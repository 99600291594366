"use strict";

function removeOtherCookies (pCookieToLive) {
    if (Array.isArray(pCookieToLive) === false) {
        pCookieToLive = [];
    }

    var pairs = document.cookie.split(";");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        var cookieKey = pair[0].trim();

        if (typeof cookieKey === "string" && (cookieKey.length > 0) && (pCookieToLive.indexOf(cookieKey) === -1)) {
            api.set_cookie(cookieKey, null); // remove it
        }
    }
}

function doMultiScopeLogin(pUsername, pPassword, pScope, pSuccess, pFailure) {
    doLoginWithOptions(pUsername, pPassword, pScope, pSuccess, pFailure);
}

function doLoginWithOptions(pUsername, pPassword, pScope, pSuccess, pFailure) {
    removeOtherCookies(["user_id", "user_hash", "expiration_date", "csrf-token", "cf_sidebar_displaymode"]);
    var login_options = { "password": pPassword};
    if (pScope !== "") {
        login_options.scope_name = pScope;
    }
    api_async.auth.login_with_options(pUsername, login_options, function (pData) {
        if (pData.user_id === undefined) {
            pFailure($.i18n._("nixps-cloudflow-login.error-login_failed"));
        } else {
            // Store our login in a cookie
            var secureOptions = { 
                expires: 2, // default 2 days
                path: "/" 
            };
            if (document.location.protocol === "https:") {
                secureOptions.secure = true;
            }
            if (pData.expiration_date !== undefined) {
                secureOptions.expires = (pData.expiration_date - new Date().getTime() / 1000) / 86400 + 1;
            }
            api.set_cookie("user_id", pData.user_id, secureOptions);
            api.set_cookie("user_hash", pData.user_hash, secureOptions);
            api.set_cookie("expiration_date", pData.expiration_date, secureOptions);
            api.set_cookie("csrf-token", pData.csrf_token, secureOptions);
            if (pData.scope_id !== undefined) {
                api.set_cookie("scope", pData.scope_id, { expires: secureOptions.expires, path: "/" });
            } 
            pSuccess(pData.user_id);
        }
    }, function (pError) {
        if(pError.error && pError.error === "No Scope specified") {
            pFailure(pError);
        }
        else if (pError.error_code !== undefined) {
            pFailure($.i18n._("nixps-cloudflow-login.error-" + pError.error_code));
        } else if (pError.error != undefined) {
            pFailure(pError.error);
        }
    });
}

function doLogin (pUsername, pPassword, pSuccess, pFailure) {
    $.when(api_defer.preferences.get_for_current_user("com.nixps.general.prerelease", "protected_cookies")).then(function(pPreference){
        if(pPreference.preferences === true) {
            doLoginwithRedirect(pUsername, pPassword, pSuccess, pFailure);
        } else {
            doLoginWithOptions(pUsername, pPassword, "" ,pSuccess, pFailure);
        }
        
    }).fail(function(pError) {
        console.error(pError);
        doLoginwithRedirect(pUsername, pPassword);
    });
    
}

function getVersionText (pVersionData) {
    var version = pVersionData.major + "." + pVersionData.minor;

    if (pVersionData.rev !== 0) {
        version += "." + pVersionData.rev;
    }

    var versionText = $.i18n._("nixps-cloudflow-login.version", [version, pVersionData.build]);
    return versionText;
}

function setFrameSession (userId) {
    return $.Deferred(function (pDefer) {
        api_defer.frame.os.get().then(function () {
            api_defer.auth.get_current_user().then(function (currentUser) {
                return api_defer.auth.create_session(currentUser.username);
            }).then(function (sessionKey) {
                var session = sessionKey.session;
                return api_defer.frame.os.set_cloudflow_session(session, {"force": true, "expiry_time": sessionKey.expiry_time});
            }).then(function () {
                pDefer.resolve();
            }).fail(function () {
                pDefer.resolve();
            });
        }).fail(function () {
            pDefer.resolve();
        });
    });
}

function doLoginwithRedirect(pUsername, pPassword, pSuccess, pFailure) {
    removeOtherCookies(["user_id", "user_hash", "expiration_date", "csrf-token", "cf_sidebar_displaymode"]);
    $.when(api_defer.auth.create_session(pUsername, {password: pPassword})).then(function(pResult){
        url_redirect({url: window.location.origin+"/portal.cgi?auth=login" ,
            method: "POST",
            data: {"session":pResult.session, "orig": getUrlParameters()}
        });
        
    }).fail(function(pError){
        if(pError.error && pError.error === "No Scope specified") {
            pFailure(pError);
        } else {
            pFailure(pError.error);
        }  
    });

    function url_redirect(options){
        var $form = $("<form />");
        
        $form.attr("action",options.url);
        $form.attr("method",options.method);
        
        for (var data in options.data) {
            $form.append('<input type="hidden" name="'+data+'" value="'+options.data[data]+'" />');
        }
        
        $("body").append($form);
        $form.submit();
        $form.remove();
   }
    
}

function getUrlParameters() {
    var orig = "";
    var params = "";
    var url = window.location.toString();
    if (url.indexOf("orig=") >= 0) {
        if(url.substring(url.indexOf("orig=")+5).indexOf("&") !== -1) {
            orig = decodeURIComponent(url.substring(url.indexOf("orig=")+5, url.indexOf("orig=")+6+url.substring(url.indexOf("orig=")+5).indexOf("&")));
        } else {
            orig = decodeURIComponent(url.substring(url.indexOf("orig=")+5));
        }
        // if(url.indexOf('portal.cgi?') >= 0) {
        //     orig = "portal.cgi?"+orig;
        // }
        // if(url.indexOf("/?")) {
        //     orig = "?"+orig;
        // }
        return orig;
    // } else if(url.indexOf("portal.cgi?") >= 0) {
    //     var start = "portal.cgi?";
    //     if (url.indexOf(start) >= 0) {
    //         params = url.substring(url.indexOf(start)+start.length);
    //     }
    //     return "portal.cgi?"+params;
    // } else if (url.indexOf("/?")){
    //     var start = "?";
    //     if (url.indexOf(start) >= 0) {
    //         params = url.substring(url.indexOf(start)+start.length);
    //     }
    //     return "?"+params;
     } else {
        return "";
    }
}
    

module.exports = {
    doLogin: doLogin,
    doMultiScopeLogin: doMultiScopeLogin,
    getVersionText: getVersionText,
    setFrameSession: setFrameSession
}
